<template>
  <div class="section summary-container">
    <label class="form-label">Summary</label>
    <div class="position-relative">
      <loading
        v-model:active="isLoading"
        :is-full-page="false"
        loader="dots"
        :opacity="1"
      />
      <div class="row row-cols-2 row-cols-md-4">
        <div class="col" v-for="(item, key) in kpis" :key="key">
          <div class="kpi-container">
            <p class="kpi-header mb-0 custom-field">
              {{ key }}
              <VTooltip
                v-if="key == 'Value'"
                class="form-tooltip"
                :triggers="['click']"
                :autoHide="true"
              >
                <img src="@/assets/question-mark.svg" />

                <template #popper>
                  {{ tooltips.cost }}
                </template>
              </VTooltip>
              <VTooltip
                v-if="key == 'Views'"
                class="form-tooltip"
                :triggers="['click']"
                :autoHide="true"
              >
                <img src="@/assets/question-mark.svg" />

                <template #popper>
                  {{ tooltips.views }}
                </template>
              </VTooltip>
              <VTooltip
                v-if="key == 'Clicks'"
                class="form-tooltip"
                :triggers="['click']"
                :autoHide="true"
              >
                <img src="@/assets/question-mark.svg" />

                <template #popper>
                  {{ tooltips.clicks }}
                </template>
              </VTooltip>
              <VTooltip
                v-if="key == 'CTR'"
                class="form-tooltip"
                :triggers="['click']"
                :autoHide="true"
              >
                <img src="@/assets/question-mark.svg" />

                <template #popper>
                  {{ tooltips.ctr }}
                </template>
              </VTooltip>
            </p>
            <p class="kpi-value mb-0">{{ item }}</p>
          </div>
        </div>
        <div class="col">
          <div class="kpi-container">
            <p class="kpi-header mb-0 custom-field">Avg. Views Per Month</p>
            <p class="kpi-value mb-0">
              <span class="month-avg">{{ monthAvg }}</span>
              <!-- <span
                class="contract-value"
                v-if="!campaignData.hideReportContractViews"
                >{{ contractVal }}/mo (contract)</span
              > -->
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row chart-preview-container">
    <div class="col-12 col-lg-8">
      <div class="section h-100">
        <label class="form-label">
          Views
          <!-- <span v-if="!campaignData.hideTableContractedViews">
            - Actual vs. Contracted</span
          > -->
        </label>
        <div class="row position-relative bar-chart-container">
          <loading
            v-model:active="isChartLoading"
            :is-full-page="false"
            loader="dots"
            :opacity="1"
          />
          <Bar
            v-if="!campaignData.hideTableContractedViews"
            :data="chartData"
            :options="chartOptions"
          />
          <Bar v-else :data="chartData2" :options="chartOptions" />
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-4">
      <div class="section preview position-relative">
        <loading
          v-model:active="isLoading"
          :is-full-page="false"
          loader="dots"
          :opacity="1"
        />
        <div class="tabs prev-section" v-if="previewTabs.length > 0">
          <ul class="nav nav-tabs" id="reportPrevTab" role="tablist">
            <li
              class="nav-item"
              role="presentation"
              v-for="(type, index) in previewTabs"
              :key="index"
            >
              <button
                class="nav-link"
                :class="{ active: index == 0 }"
                :id="type.type + '-tab'"
                data-bs-toggle="tab"
                :data-bs-target="'#' + type.type"
                type="button"
                role="tab"
                :aria-controls="type.type"
                aria-selected="true"
              >
                {{ type.typeName }}
              </button>
            </li>
          </ul>
          <div class="tab-content" id="reportPrevTabContent">
            <div
              class="tab-pane fade"
              v-for="(type, index) in previewTabs"
              :key="index"
              :class="{ 'show active': index == 0 }"
              :id="type.type"
              role="tabpanel"
              :aria-labelledby="type.type + '-tab'"
            >
              <PreviewResults
                :previewInfo="campaignData.previewInfo"
                v-model="platform"
                :adType="type.typeId"
                :reportView="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tabs section channel-section position-relative">
    <loading
      v-model:active="isLoading"
      :is-full-page="false"
      loader="dots"
      :opacity="1"
    />
    <div class="channel-table">
      <ul class="nav nav-tabs" id="reportTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active"
            id="summary-tab"
            data-bs-toggle="tab"
            data-bs-target="#summary"
            type="button"
            role="tab"
            aria-controls="summary"
            aria-selected="true"
          >
            By Channel
          </button>
        </li>
        <!-- <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="broker-tab"
            data-bs-toggle="tab"
            data-bs-target="#broker"
            type="button"
            role="tab"
            aria-controls="broker"
            aria-selected="false"
          >
            Broker
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="tenant-tab"
            data-bs-toggle="tab"
            data-bs-target="#tenant"
            type="button"
            role="tab"
            aria-controls="tenant"
            aria-selected="false"
          >
            Tenant
          </button>
        </li> -->
      </ul>
      <div class="tab-content" id="reportTabContent">
        <div
          class="tab-pane fade show active"
          id="summary"
          role="tabpanel"
          aria-labelledby="summary-tab"
        >
          <div class="channel-data-container">
            <div class="row">
              <div class="col">
                <div class="platformInfo platformHeader">&nbsp;</div>
              </div>
              <div class="col">
                <div class="platformInfo platformHeader custom-field">
                  Value
                  <VTooltip
                    class="form-tooltip"
                    :triggers="['click']"
                    :autoHide="true"
                  >
                    <img src="@/assets/question-mark.svg" />

                    <template #popper>
                      {{ tooltips.channelSpendTotal }}
                    </template>
                  </VTooltip>
                </div>
              </div>
              <div class="col">
                <div class="platformInfo platformHeader custom-field">
                  Views
                  <VTooltip
                    class="form-tooltip"
                    :triggers="['click']"
                    :autoHide="true"
                  >
                    <img src="@/assets/question-mark.svg" />

                    <template #popper>
                      {{ tooltips.channelViewsTotal }}
                    </template>
                  </VTooltip>
                </div>
              </div>
              <div class="col">
                <div class="platformInfo platformHeader custom-field">
                  Clicks
                  <VTooltip
                    class="form-tooltip"
                    :triggers="['click']"
                    :autoHide="true"
                  >
                    <img src="@/assets/question-mark.svg" />

                    <template #popper>
                      {{ tooltips.channelClicksTotal }}
                    </template>
                  </VTooltip>
                </div>
              </div>
              <!-- <div class="col">
                <div class="platformInfo platformHeader custom-field">
                  CTR
                  <VTooltip
                    class="form-tooltip"
                    :triggers="['click']"
                    :autoHide="true"
                  >
                    <img src="@/assets/question-mark.svg" />

                    <template #popper>
                      {{ tooltips.channelCtrTotal }}
                    </template>
                  </VTooltip>
                </div>
              </div>
              <div class="col">
                <div class="platformInfo platformHeader">Reach</div>
              </div>
              <div class="col">
                <div class="platformInfo platformHeader">Engagement</div>
              </div> -->
            </div>
            <div
              class="row"
              v-for="(item, index) in detailChannelData.total"
              :key="index"
            >
              <div class="col">
                <div
                  class="platformInfo platformTitle"
                  :class="item.name + '-bubble'"
                >
                  <img
                    class="platformIcon"
                    :src="getImgUrl(item.icon)"
                    :alt="item.name"
                  />
                  <span>
                    {{
                      item.name == "twitter"
                        ? "X (TWITTER)"
                        : item.name == "video"
                        ? "YOUTUBE"
                        : item.name.toUpperCase()
                    }}
                  </span>
                </div>
              </div>
              <div class="col" v-for="(val, key) in item.props" :key="key">
                <div
                  class="platformInfo d-flex align-items-center"
                  :class="item.name + '-bubble'"
                >
                  <span>{{ val }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="broker"
          role="tabpanel"
          aria-labelledby="broker-tab"
        >
          <div class="channel-data-container">
            <div class="row">
              <div class="col">
                <div class="platformInfo platformHeader">&nbsp;</div>
              </div>
              <div class="col">
                <div class="platformInfo platformHeader custom-field">
                  Spend
                  <VTooltip
                    class="form-tooltip"
                    :triggers="['click']"
                    :autoHide="true"
                  >
                    <img src="@/assets/question-mark.svg" />

                    <template #popper>
                      {{ tooltips.channelSpendBroker }}
                    </template>
                  </VTooltip>
                </div>
              </div>
              <div class="col">
                <div class="platformInfo platformHeader custom-field">
                  Views
                  <VTooltip
                    class="form-tooltip"
                    :triggers="['click']"
                    :autoHide="true"
                  >
                    <img src="@/assets/question-mark.svg" />

                    <template #popper>
                      {{ tooltips.channelViewsBroker }}
                    </template>
                  </VTooltip>
                </div>
              </div>
              <div class="col">
                <div class="platformInfo platformHeader custom-field">
                  Clicks
                  <VTooltip
                    class="form-tooltip"
                    :triggers="['click']"
                    :autoHide="true"
                  >
                    <img src="@/assets/question-mark.svg" />

                    <template #popper>
                      {{ tooltips.channelClicksBroker }}
                    </template>
                  </VTooltip>
                </div>
              </div>
              <!-- <div class="col">
                <div class="platformInfo platformHeader custom-field">
                  CTR
                  <VTooltip
                    class="form-tooltip"
                    :triggers="['click']"
                    :autoHide="true"
                  >
                    <img src="@/assets/question-mark.svg" />

                    <template #popper>
                      {{ tooltips.channelCtrTotal }}
                    </template>
                  </VTooltip>
                </div>
              </div>
              <div class="col">
                <div class="platformInfo platformHeader">Reach</div>
              </div>
              <div class="col">
                <div class="platformInfo platformHeader">Engagement</div>
              </div> -->
            </div>
            <div
              class="row"
              v-for="(item, index) in detailChannelData.broker"
              :key="index"
            >
              <div class="col">
                <div
                  class="platformInfo platformTitle"
                  :class="item.name + '-bubble'"
                >
                  <img
                    class="platformIcon"
                    :src="getImgUrl(item.icon)"
                    :alt="item.name"
                  />
                  <span>
                    {{
                      item.name == "twitter"
                        ? "X (TWITTER)"
                        : item.name.toUpperCase()
                    }}
                  </span>
                </div>
              </div>
              <div class="col" v-for="(val, key) in item.props" :key="key">
                <div
                  class="platformInfo d-flex align-items-center"
                  :class="item.name + '-bubble'"
                >
                  <span>{{ val }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="tenant"
          role="tabpanel"
          aria-labelledby="tenant-tab"
        >
          <div class="channel-data-container">
            <div class="row">
              <div class="col">
                <div class="platformInfo platformHeader">&nbsp;</div>
              </div>
              <div class="col">
                <div class="platformInfo platformHeader custom-field">
                  Spend
                  <VTooltip
                    class="form-tooltip"
                    :triggers="['click']"
                    :autoHide="true"
                  >
                    <img src="@/assets/question-mark.svg" />

                    <template #popper>
                      {{ tooltips.channelSpendTenant }}
                    </template>
                  </VTooltip>
                </div>
              </div>
              <div class="col">
                <div class="platformInfo platformHeader custom-field">
                  Views
                  <VTooltip
                    class="form-tooltip"
                    :triggers="['click']"
                    :autoHide="true"
                  >
                    <img src="@/assets/question-mark.svg" />

                    <template #popper>
                      {{ tooltips.channelViewsTenant }}
                    </template>
                  </VTooltip>
                </div>
              </div>
              <div class="col">
                <div class="platformInfo platformHeader custom-field">
                  Clicks
                  <VTooltip
                    class="form-tooltip"
                    :triggers="['click']"
                    :autoHide="true"
                  >
                    <img src="@/assets/question-mark.svg" />

                    <template #popper>
                      {{ tooltips.channelClicksTenant }}
                    </template>
                  </VTooltip>
                </div>
              </div>
              <!-- <div class="col">
                <div class="platformInfo platformHeader custom-field">
                  CTR
                  <VTooltip
                    class="form-tooltip"
                    :triggers="['click']"
                    :autoHide="true"
                  >
                    <img src="@/assets/question-mark.svg" />

                    <template #popper>
                      {{ tooltips.channelCtrTotal }}
                    </template>
                  </VTooltip>
                </div>
              </div>
              <div class="col">
                <div class="platformInfo platformHeader">Reach</div>
              </div>
              <div class="col">
                <div class="platformInfo platformHeader">Engagement</div>
              </div> -->
            </div>
            <div
              class="row"
              v-for="(item, index) in detailChannelData.tenant"
              :key="index"
            >
              <div class="col">
                <div
                  class="platformInfo platformTitle"
                  :class="item.name + '-bubble'"
                >
                  <img
                    class="platformIcon"
                    :src="getImgUrl(item.icon)"
                    :alt="item.name"
                  />
                  <span>
                    {{
                      item.name == "twitter"
                        ? "X (TWITTER)"
                        : item.name.toUpperCase()
                    }}
                  </span>
                </div>
              </div>
              <div class="col" v-for="(val, key) in item.props" :key="key">
                <div
                  class="platformInfo d-flex align-items-center"
                  :class="item.name + '-bubble'"
                >
                  <span>{{ val }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--  contacts identified/companies identified-->

  <div
    class="section position-relative"
    v-if="
      showIdentifiedContacts && this.campaignId !== '672a7b5e76f6b4d97f9b3ed8'
    "
  >
    <loading
      v-model:active="isContactsLoading"
      :is-full-page="false"
      loader="dots"
      :opacity="1"
    />
    <div>
      <label class="form-label">
        Contacts Identified from Property Page
        <VTooltip class="form-tooltip" :triggers="['click']" :autoHide="true">
          <img src="@/assets/question-mark.svg" />

          <template #popper>
            <div style="margin-bottom: 0.5em">
              <b> How do we get this information? </b>
            </div>
            <div>
              A mix of our development team and licensing different databases.
              This includes: IP Data, Web-crawling coverage, Language AI models,
              and Commercial registries. We DO NOT get this information from the
              advertising platforms.
            </div>
            <br />
            <div style="margin-bottom: 0.5em">
              <b> Is this legal and compliant? </b>
            </div>
            <div>
              US-Only – Our services are configured to activate only when we can
              confirm that a user’s IP address is located within the United
              States. If we cannot definitively identify an IP address as being
              in the U.S., our script will not record any data from that user.
            </div>
          </template>
        </VTooltip>
      </label>
    </div>
    <div class="table-container">
      <DatatableComponent
        :data="contacts_identified"
        :columns="columns1"
        :options="{
          paging: false,
          searching: false,
          info: false,
          responsive: true,
          stripeClasss: [],
          ordering: true,
          fixedHeader: true,
          scrollX: true,
        }"
        :keys="keys1"
      />
    </div>
  </div>

  <div
    class="section position-relative"
    v-if="
      showIdentifiedCompanies && this.campaignId !== '672a7b5e76f6b4d97f9b3ed8'
    "
  >
    <loading
      v-model:active="isCompaniesLoading"
      :is-full-page="false"
      loader="dots"
      :opacity="1"
    />
    <div>
      <label class="form-label">
        Companies Identified from Property Page
        <VTooltip class="form-tooltip" :triggers="['click']" :autoHide="true">
          <img src="@/assets/question-mark.svg" />

          <template #popper>
            <div style="margin-bottom: 0.5em">
              <b> How do we get this information? </b>
            </div>
            <div>
              A mix of our development team and licensing different databases.
              This includes: IP Data, Web-crawling coverage, Language AI models,
              and Commercial registries. We DO NOT get this information from the
              advertising platforms.
            </div>
          </template>
        </VTooltip>
      </label>
    </div>
    <div class="table-container">
      <DatatableComponent
        :data="companies_identified"
        :columns="columns2"
        :options="{
          paging: false,
          searching: false,
          info: false,
          responsive: true,
          stripeClasss: [],
          ordering: true,
          fixedHeader: true,
          scrollX: true,
        }"
        :keys="keys2"
      />
    </div>
  </div>

  <div class="section position-relative companies-container">
    <div class="row">
      <label class="col form-label">Companies Reached</label>
      <div class="col text-end">
        <JsonCSV
          :data="reachedCampaignsExport"
          v-if="!isCompaniesReachedLoading && reachedCampaignsExport.length > 0"
          :name="campaignData.campaignName + '-Reached-Companies.csv'"
          class="export-btn"
        >
          <button class="btn btn-dark wti-btn-black">Export to CSV</button>
        </JsonCSV>
      </div>
    </div>
    <div class="position-relative section-data-conatiner">
      <loading
        v-model:active="isCompaniesReachedLoading"
        :is-full-page="false"
        loader="dots"
        :opacity="1"
      />
      <div class="position-relative companies-table">
        <div>
          <p class="location-label">BROKER MARKETS</p>
        </div>
        <div class="location-container">
          <span
            v-for="(location, index) in locations"
            :key="index"
            class="location-item"
          >
            {{ location }}
          </span>
        </div>
        <DatatableComponent
          v-if="!isTableLoading && !isLoading"
          :data="companies"
          :columns="columns"
          :options="{
            paging: false,
            searching: false,
            info: false,
            responsive: true,
            stripeClasss: [],
            ordering: true,
            order: [[3, 'desc']],
            fixedHeader: true,
            scrollY:
              companies.length > 0
                ? companies.length * 55 > 800
                  ? 800
                  : companies.length * 55
                : 80,
          }"
          :keys="keys"
        />
      </div>
    </div>
  </div>
  <div class="section position-relative">
    <label class="form-label">Top Websites Your Ad Was On</label>
    <div class="position-relative section-data-conatiner">
      <loading
        v-model:active="isWebsitesLoading"
        :is-full-page="false"
        loader="dots"
        :opacity="1"
      />
      <div class="row" v-if="!isWebsitesLoading">
        <div
          class="col-12 col-md-6 col-lg-3"
          v-for="($website, index) in websites"
          :key="index"
        >
          <span
            class="website-container w-100"
            :class="{
              'top-border':
                (isMobile && index < 1) ||
                (isTablet && index < 2) ||
                (!isMobile && !isTablet && index < 4),
            }"
          >
            <img :src="getImgUrl($website.logo, false)" />
            <span>{{ $website.name }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="section overflow-auto position-relative">
      <label class="form-label">Leads</label>
      <div class="position-relative section-data-conatiner">
        <loading
          v-model:active="isLeadTableLoading"
          :is-full-page="false"
          loader="dots"
          :opacity="1"
        />
        <div v-if="!isLeadTableLoading" class="companies-table">
          <DatatableComponent
            :data="leads"
            :columns="leadsColumns"
            :options="{
              paging: false,
              searching: false,
              info: false,
              responsive: true,
              stripeClasss: [],
              ordering: true,
              order: [[4, 'desc']],
            }"
            :keys="leadsKeys"
          />
        </div>
      </div>
    </div>-->
  <div class="section position-relative" v-if="campaignLikes.length > 0">
    <div class="row">
      <label class="col form-label">People Who "Liked" Your Ad</label>
      <div class="col text-end">
        <JsonCSV
          :data="campaignLikesExport"
          :name="campaignData.campaignName + '-Likes.csv'"
          class="export-btn move-up"
        >
          <button class="btn btn-dark wti-btn-black">Export to CSV</button>
        </JsonCSV>
      </div>
    </div>

    <div class="position-relative section-data-conatiner">
      <loading
        v-model:active="isCompaniesLikesLoading"
        :is-full-page="false"
        loader="dots"
        :opacity="1"
      />
      <div>
        <datatable-component
          v-if="!isCompaniesLikesLoading"
          :keys="likeskeys"
          :columns="likesColumns"
          :data="campaignLikes"
          :options="{
            paging: false,
            searching: false,
            info: false,
            responsive: true,
            scrollY:
              campaignLikes.length > 0
                ? campaignLikes.length * 36 > 400
                  ? 400
                  : campaignLikes.length * 36
                : 40,
          }"
        />
      </div>
    </div>
  </div>
  <div
    class="section position-relative"
    v-if="
      showIdentifiedContacts && this.campaignId === '672a7b5e76f6b4d97f9b3ed8'
    "
  >
    <loading
      v-model:active="isContactsLoading"
      :is-full-page="false"
      loader="dots"
      :opacity="1"
    />
    <div>
      <label class="form-label">
        Contacts Identified from Property Page
        <VTooltip class="form-tooltip" :triggers="['click']" :autoHide="true">
          <img src="@/assets/question-mark.svg" />

          <template #popper>
            <div style="margin-bottom: 0.5em">
              <b> How do we get this information? </b>
            </div>
            <div>
              A mix of our development team and licensing different databases.
              This includes: IP Data, Web-crawling coverage, Language AI models,
              and Commercial registries. We DO NOT get this information from the
              advertising platforms.
            </div>
            <br />
            <div style="margin-bottom: 0.5em">
              <b> Is this legal and compliant? </b>
            </div>
            <div>
              US-Only – Our services are configured to activate only when we can
              confirm that a user’s IP address is located within the United
              States. If we cannot definitively identify an IP address as being
              in the U.S., our script will not record any data from that user.
            </div>
          </template>
        </VTooltip>
      </label>
    </div>
    <div class="table-container">
      <DatatableComponent
        :data="contacts_identified"
        :columns="columns1"
        :options="{
          paging: false,
          searching: false,
          info: false,
          responsive: true,
          stripeClasss: [],
          ordering: true,
          fixedHeader: true,
          scrollX: true,
        }"
        :keys="keys1"
      />
    </div>
  </div>
  <div
    class="section position-relative"
    v-if="
      showIdentifiedCompanies && this.campaignId === '672a7b5e76f6b4d97f9b3ed8'
    "
  >
    <loading
      v-model:active="isCompaniesLoading"
      :is-full-page="false"
      loader="dots"
      :opacity="1"
    />
    <div>
      <label class="form-label">
        Companies Identified from Property Page
        <VTooltip class="form-tooltip" :triggers="['click']" :autoHide="true">
          <img src="@/assets/question-mark.svg" />

          <template #popper>
            <div style="margin-bottom: 0.5em">
              <b> How do we get this information? </b>
            </div>
            <div>
              A mix of our development team and licensing different databases.
              This includes: IP Data, Web-crawling coverage, Language AI models,
              and Commercial registries. We DO NOT get this information from the
              advertising platforms.
            </div>
          </template>
        </VTooltip>
      </label>
    </div>
    <div class="table-container">
      <DatatableComponent
        :data="companies_identified"
        :columns="columns2"
        :options="{
          paging: false,
          searching: false,
          info: false,
          responsive: true,
          stripeClasss: [],
          ordering: true,
          fixedHeader: true,
          scrollX: true,
        }"
        :keys="keys2"
      />
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import "@/assets/vars.sass"
@import 'datatables.net-bs5'

.kpi-container
  border: 1px solid $LightGreyBorder
  border-radius: 20px
  width: 100%
  min-height: 101px
  height: 100%
  .kpi-header
    font-weight: 600
    font-size: 16px
    line-height: 20px
    color: $Main
    padding: 23px 30px 12px
    +laptop
      font-size: 15px
    +mobile
      font-size: 14px
      padding: 14px 14px 12px
  .kpi-value
    font-weight: 600
    font-size: 18px
    line-height: 23px
    padding: 0 30px 22px
    color: $Main
    +laptop
      font-size: 17px
    +mobile
      font-size: 16px
      padding: 0 14px 14px
    span
      font-weight: 600
    .month-avg
      margin-right: 8px
    .contract-value
      color: $Blue
      font-size: 12px
      font-weight: 400
      line-height: 15px
      display: inline-block
  +mobile
    margin-bottom: 10px
    height: 90%

.chart-preview-container
  margin-bottom: 30px
  +mobile
    margin-bottom: 0
  .col-12
    +mobile
      margin-bottom: 30px
  .bar-chart-container
    height: 550px
    +mobile
      padding-top: 10px
      height: 300px
  .preview
    height: 100%
    padding: 30px 12px
    margin-bottom: 0
    .vl-overlay .vl-background
      border-radius: 10px
.channel-table
  .title
    border: 1px solid $LightGreyBorder
    border-radius: 10px
    padding: 10px
    color: $LightGrey
    font-size: 0.65rem
  .platformInfo.platformHeader
    font-weight: 600
    font-size: 16px
    line-height: 20px
    text-align: center
    color: $Main
    min-height: 34px
    margin-bottom: 0
    +mobile
      font-size: 14px
  .platformInfo
    border-radius: 10px
    font-weight: 600
    font-size: 14px
    line-height: 18px
    min-height: 44px
    margin-bottom: 9px
    +mobile
      font-size: 12px
    .platformIcon
      height: 20px
      margin: 11px 10px 11px 20px
      +mobile
        margin: 11px 5px 11px 11px
    span
      width: 100%
      text-align: center
  .platformInfo.platformTitle span
    font-size: 12px
    font-weight: 600
    +mobile
      font-size: 10px
  .linkedin-bubble
    color: $Main
    background-color: $LinkedinBg
  .display-bubble
    color: $Main
    background-color: $DisplayBg
  .video-bubble
    color: $Main
    background-color: $YoutubeBg
  .facebook-bubble
    color: $Main
    background-color: $FacebookBg
  .instagram-bubble
    color: $Main
    background-color: $InstagramBg
  .search-bubble
    color: $Main
    background-color: $SearchBg
  .twitter-bubble
    color: $Main
    background-color: $TwitterBg
.section-data-conatiner
  min-height: 80px
.companies-table
  min-height: 30vh
  overflow-y: auto
  .datatable
    width: 99%
    thead
      th
        white-space: nowrap
    tbody
      td
        white-space: nowrap
.location-label
  font-size: 14px
  font-weight: 700
  line-height: 20px
.location-container
  margin: 15px 0
  white-space: normal
  .location-item
    background: #EEF1F9
    font-size: 14px
    line-height: 20px
    color: #06152B
    padding: 8px 20px
    border-radius: 12px
    margin-right: 10px
    margin-bottom: 5px
    display: inline-block
.website-container
  border-bottom: 1px solid $LightGreyBorder
  display: inline-block
  font-size: 14px
  line-height: 1.3
  padding: 15px 10px
  color: #06152B
  img
    height: 25px
    width: 25px
    margin: 0 19px 0 0
.website-container.top-border
  border-top: 1px solid $LightGreyBorder
.export-btn button
  font-weight: 600
  font-size: 14px
  line-height: 18px
  +mobile
    font-size: 12px
    border-radius: 0.375rem
.export-btn.move-up button
  margin-top: -10px
  +mobile
    margin-top: 0
</style>

<script>
import { ref } from "vue";
import api from "@/services/BackendConnection";
import moment from "moment";
import PreviewResults from "@/components/PreviewResults.vue";
import DatatableComponent from "@/components/DatatableComponent.vue";
import tooltips from "@/utils/tooltips.json";
import $ from "jquery";
import mockData from "@/utils/mockData.json";
import JsonCSV from "vue-json-csv";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  // LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Bar } from "vue-chartjs";
import chartjsPluginAnnotation from "chartjs-plugin-annotation";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  // LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  chartjsPluginAnnotation
);

export default {
  name: "CampaignReport",
  emits: [
    "updateIsLoading",
    "updateCampaignData",
    "updateChartData",
    "updateCompaniesData",
    "enablePDFDownload",
    "updateWebsitesData",
    "updateLikesData",
    "updateContactsIdentifiedData",
    "updateCompaniesIdentifiedData",
  ],
  setup(props) {
    //identified contacts/companies props
    var contacts_data = [
      // {
      //   name: "Annalisa Hepburn",
      //   jobTitle: "Senior Client Service Specialist",
      //   logo: "Annalisa_Hepburn.jpeg",
      //   company: "OnPace Partners",
      //   email: "annalisa@onpacepartners.com",
      //   LinkedIn: "http://www.linkedin.com/in/annalisa-hepburn-9578aa46",
      //   location: "Atlanta, GA",
      //   website: "http://www.onpacepartners.com",
      //   pageViews: 1744,
      //   viewed: null,
      //   lastSeen: moment("2024-09-08").format("MMM D, YYYY"),
      // },
      // {
      //   name: "Enoch Hayase",
      //   jobTitle: "Managing Director",
      //   logo: "Enoch_Hayase.jpeg",
      //   company: "Ares Management Corporation",
      //   email: null,
      //   LinkedIn: "http://www.linkedin.com/in/enoch-hayase-1229154",
      //   location: "New York, NY",
      //   website: "http://www.aresmgmt.com",
      //   pageViews: 32,
      //   viewed: null,
      //   lastSeen: moment("2024-09-15").format("MMM D, YYYY"),
      // },
      // {
      //   name: "Walker Collier",
      //   jobTitle: "Managing Partner",
      //   logo: "Walker_Collier.jpeg",
      //   company: "Trinity Capital Advisors",
      //   email: "cwc@trinitycapitaladvisors.com",
      //   LinkedIn: "http://www.linkedin.com/in/walker-collier",
      //   location: " Broomfield, CO",
      //   website: "http://www.trinitycapitaladvisors.com",
      //   pageViews: 222,
      //   viewed: null,
      //   lastSeen: moment("2024-09-07").format("MMM D, YYYY"),
      // },
      // {
      //   name: "Cesar Ortiz",
      //   jobTitle: "Gerente de ventas SME Michoacán",
      //   logo: "Cesar_Ortiz.jpeg",
      //   company: "AT&T, México",
      //   email: null,
      //   LinkedIn: "http://www.linkedin.com/in/cesar-eduardo-ortiz-e-58a849127",
      //   location: "Durham, NC",
      //   website: "http://www.att.com.mx",
      //   pageViews: 222,
      //   viewed: null,
      //   lastSeen: moment("2024-09-08").format("MMM D, YYYY"),
      // },
      // {
      //   name: "Joseph Aronoff",
      //   jobTitle: "Authentics Event Staff",
      //   logo: "Joseph_Aronoff.jpeg",
      //   company: "Detroit Tigers",
      //   email: "jaronoff38@gmail.com",
      //   LinkedIn: "http://www.linkedin.com/in/joseph-aronoff-4178291a1",
      //   location: "Ashburn, VA",
      //   website: "http://www.tigers.com",
      //   pageViews: 573,
      //   viewed: null,
      //   lastSeen: moment("2024-09-12").format("MMM D, YYYY"),
      // },
      // {
      //   name: "Scott Nicholson",
      //   jobTitle: "Managing Director",
      //   logo: "Scott_Nicholson.jpeg",
      //   company: "InSite Real Estate",
      //   email: "snicholson@insiterealestate.com",
      //   LinkedIn: "http://www.linkedin.com/in/scott-nicholson-89814413",
      //   location: "Hinsdale, IL",
      //   website: "http://www.insiterealestate.com",
      //   pageViews: 864,
      //   viewed: null,
      //   lastSeen: moment("2024-09-14").format("MMM D, YYYY"),
      // },
      // {
      //   name: "Trey Adams",
      //   jobTitle: "Managing Partner at Atlas Stark Holdings",
      //   logo: "Trey_Adams.jpeg",
      //   company: "Atlas Stark",
      //   email: null,
      //   LinkedIn: "https://linkedin.com/in/trey-adams-bb572913",
      //   location: "Fayetteville, NC",
      //   website: "https://atlasstark.com",
      //   pageViews: 21,
      //   viewed: null,
      //   lastSeen: moment("2024-09-11").format("MMM D, YYYY"),
      // },
      // {
      //   name: "Leslie Holmes",
      //   jobTitle: "Client Services Team Lead",
      //   logo: "Leslie_Holmes.jpeg",
      //   company: "CBRE | Raleigh",
      //   email: "leslie.holmes@cbre-raleigh.com",
      //   LinkedIn: "http://www.linkedin.com/in/leslieanneholmes",
      //   location: "Broomfield, CO",
      //   website: "http://www.cbre.com",
      //   pageViews: 467,
      //   viewed: null,
      //   lastSeen: moment("2024-09-05").format("MMM D, YYYY"),
      // },
      // {
      //   name: "Ranjana Sterling",
      //   jobTitle: "Senior Marketing Strategist",
      //   logo: "Ranjana_Sterling.jpeg",
      //   company: "CBRE",
      //   email: "ranjana.sterling@cbre.com",
      //   LinkedIn: "http://www.linkedin.com/in/ranjana-sterling-a566594b",
      //   location: "Kirkland, WA",
      //   website: "http://www.cbre.com",
      //   pageViews: 432,
      //   viewed: null,
      //   lastSeen: moment("2024-09-06").format("MMM D, YYYY"),
      // },
      // {
      //   name: "Brandon Nickoles",
      //   jobTitle: "Assistant Development Director",
      //   logo: "Brandon_Nickoles.jpeg",
      //   company: "Wexford Science & Technology, LLC",
      //   email: "brandonnickoles@gmail.com",
      //   LinkedIn: "http://www.linkedin.com/in/brandon-nickoles-947054105",
      //   location: "Broomfield, CO",
      //   website: "http://www.wexfordscitech.com",
      //   pageViews: 43,
      //   viewed: null,
      //   lastSeen: moment("2024-09-06").format("MMM D, YYYY"),
      // },
      // {
      //   name: "Ben Brenner",
      //   jobTitle: "VP of Sales",
      //   logo: "Ben_Brenner.jpeg",
      //   company: "Keen Decision Systems",
      //   email: "ben.brenner@keends.com",
      //   LinkedIn: "http://www.linkedin.com/in/ben-brenner-9b47698a",
      //   location: "New York, NY",
      //   website: "http://www.keends.com",
      //   pageViews: 83,
      //   viewed: null,
      //   lastSeen: moment("2024-09-05").format("MMM D, YYYY"),
      // },
      // {
      //   name: "Ellen Lerner",
      //   jobTitle: "Retired from full-time work | Senior Account Manager",
      //   logo: "Ellen_Lerner.jpeg",
      //   company: "Epam Systems",
      //   email: null,
      //   LinkedIn: "https://linkedin.com/in/ellenlerner",
      //   location: "Albany, NY",
      //   website: "https://epam.com",
      //   pageViews: 95,
      //   viewed: null,
      //   lastSeen: moment("2024-09-18").format("MMM D, YYYY"),
      // },
      // {
      //   name: "Lauren Berry",
      //   jobTitle: "Senior Manager",
      //   logo: "Lauren_Berry.jpeg",
      //   company: "Maxis Advisors",
      //   email: "l.berry@maxisadvisors.com",
      //   LinkedIn: "http://www.linkedin.com/in/laurenjohnsonberry",
      //   location: "Charlotte, NC",
      //   website: "http://www.maxisadvisors.com",
      //   pageViews: 532,
      //   viewed: null,
      //   lastSeen: moment("2024-09-02").format("MMM D, YYYY"),
      // },
      // {
      //   name: "Mary Moshofsky",
      //   jobTitle: "Office Advisory & Transaction Services",
      //   logo: "Mary_Moshofsky.jpeg",
      //   company: "CBRE",
      //   email: "marylynn.moshofsky@cbre.com",
      //   LinkedIn: "http://www.linkedin.com/in/mary-lynn-moshofsky-3582855a",
      //   location: "Dallas, TX",
      //   website: "http://www.cbre.com",
      //   pageViews: 1320,
      //   viewed: null,
      //   lastSeen: moment("2024-09-01").format("MMM D, YYYY"),
      // },
      // {
      //   name: "Zach Vall-SpinosaZach Vall-Spinosa",
      //   jobTitle: "Executive Vice President / Partner",
      //   logo: "Zach_Vall_Spinosa.jpeg",
      //   company: "Kidder Mathews",
      //   email: "zach.vallspinosa@kidder.com",
      //   LinkedIn: "http://www.linkedin.com/in/zach-vall-spinosa-bb694532",
      //   location: "Kirkland, WA",
      //   website: "http://www.kidder.com",
      //   pageViews: 249,
      //   viewed: null,
      //   lastSeen: moment("2024-09-18").format("MMM D, YYYY"),
      // },
      {
        name: "Shannon Loew",
        jobTitle: "Vice President, Real Estate & Facilities",
        logo: "shannon_loew.jpeg",
        company: "Amazon",
        email: "shloew@amazon.com",
        LinkedIn: "https://www.linkedin.com/in/shannon-loew-0614362/",
        location: "Seattle, WA",
        website: "https://www.amazon.com/",
        pageViews: 21,
        viewed: null,
        lastSeen: moment("2024-09-12").format("MMM D, YYYY"),
      },
      {
        name: "Craig Irwin",
        jobTitle: "Director of Real Estate",
        logo: "craig_irwin.jpeg",
        company: "Deloitte",
        email: "cirwin@deloitte.ca",
        LinkedIn: "https://www.linkedin.com/in/craig-irwin-44a7a564/",
        location: "Toronto, ON",
        website:
          "https://www2.deloitte.com/ca/en/pages/about-deloitte/articles/home.html",
        pageViews: 34,
        viewed: null,
        lastSeen: moment("2024-09-08").format("MMM D, YYYY"),
      },
      {
        name: "Jeff Hardman",
        jobTitle: "Vice President, Real Estate",
        logo: null,
        company: "Home Depot",
        email: "jeffhardman@homedepot.com",
        LinkedIn: "https://www.linkedin.com/in/jeff-hardman-674b1310/",
        location: "Atlanta, GA",
        website: "https://www.homedepot.com/",
        pageViews: 12,
        viewed: null,
        lastSeen: moment("2024-09-06").format("MMM D, YYYY"),
      },
      {
        name: "Trey Conway",
        jobTitle: "Director of Real Estate",
        logo: "trey_conway.jpeg",
        company: "Home Depot",
        email: "treyconway@homedepot.com",
        LinkedIn: "https://www.linkedin.com/in/trey-conway-2391b11a/",
        location: "Atlanta, GA",
        website: "https://www.homedepot.com/",
        pageViews: 52,
        viewed: null,
        lastSeen: moment("2024-09-18").format("MMM D, YYYY"),
      },
      {
        name: "Randall Fears",
        jobTitle: "Real Estate",
        logo: "randall_fears.jpeg",
        company: "Tesla",
        email: "annalisa@onpacepartners.com",
        LinkedIn: "http://www.linkedin.com/in/annalisa-hepburn-9578aa46",
        location: "Austin, TX",
        website: "https://www.tesla.com/",
        pageViews: 12,
        viewed: null,
        lastSeen: moment("2024-09-03").format("MMM D, YYYY"),
      },
    ];
    var companies_data = [
      {
        company: "Deloitte",
        location: "Toronto, ON",
        logo: "deloitte_logo.jpeg",
        website: "http://www.deloitte.com/",
        industry: "Business Consulting and Services",
        size: "10,001+ employees",
        pageViews: 2930,
        viewed: null,
        lastSeen: moment("2024-09-18").format("MMM D, YYYY"),
      },
      {
        company: "GMC",
        location: "Detroit, MI",
        logo: "GMC.jpeg",
        website: "https://www.gmc.com/",
        industry: "Motor Vehicle Manufacturing",
        size: "10,001+ employees",
        pageViews: 1743,
        viewed: null,
        lastSeen: moment("2024-09-01").format("MMM D, YYYY"),
      },
      {
        company: "Ford",
        location: "Dearborn, MI",
        logo: "ford_motor_company_logo.jpeg",
        website: "https://corporate.ford.com/",
        industry: "Motor Vehicle Manufacturing",
        size: "10,001+ employees",
        pageViews: 1055,
        viewed: null,
        lastSeen: moment("2024-09-13").format("MMM D, YYYY"),
      },
      {
        company: "Tesla",
        location: "Austin, TX",
        logo: "tesla_motors_logo.jpeg",
        website: "https://www.tesla.com/",
        industry: "Motor Vehicle Manufacturing",
        size: "10,001+ employees",
        pageViews: 1937,
        viewed: null,
        lastSeen: moment("2024-09-11").format("MMM D, YYYY"),
      },
      {
        company: "Toyota",
        location: "Toyota, Aichi",
        logo: "toyota_logo.jpeg",
        website: "http://www.toyota-global.com/",
        industry: "Motor Vehicle Manufacturing",
        size: "10,001+ employees",
        pageViews: 1203,
        viewed: null,
        lastSeen: moment("2024-09-11").format("MMM D, YYYY"),
      },
      {
        company: "Amazon",
        location: "Seattle, WA",
        logo: "amazon_logo.jpeg",
        website: "https://www.aboutamazon.com/",
        industry: "Software Development",
        size: "10,001+ employees",
        pageViews: 2069,
        viewed: null,
        lastSeen: moment("2024-09-17").format("MMM D, YYYY"),
      },
      {
        company: "Home Depot",
        location: "Atlanta, GA",
        logo: "the_home_depot_logo.jpeg",
        website: "http://www.careers.homedepot.com",
        industry: "Retail",
        size: "10,001+ employees",
        pageViews: 1026,
        viewed: null,
        lastSeen: moment("2024-09-18").format("MMM D, YYYY"),
      },
      {
        company: "Target",
        location: "Minneapolis, MN",
        logo: "Target.png",
        website: "http://www.target.com/",
        industry: "Retail",
        size: "10,001+ employees",
        pageViews: 2418,
        viewed: null,
        lastSeen: moment("2024-09-16").format("MMM D, YYYY"),
      },
      {
        company: "Purolator",
        location: "Mississauga, ON",
        logo: "purolatorinc_logo.jpeg",
        website: "https://www.purolator.com",
        industry: "Freight and Package Transportation",
        size: "10,001+ employees",
        pageViews: 1873,
        viewed: null,
        lastSeen: moment("2024-09-16").format("MMM D, YYYY"),
      },
      {
        company: "UPS",
        location: "Atlanta, GA",
        logo: "ups_logo.jpeg",
        website: "https://about.ups.com/",
        industry: "Truck Transportation",
        size: "10,001+ employees",
        pageViews: 1638,
        viewed: null,
        lastSeen: moment("2024-09-12").format("MMM D, YYYY"),
      },
      {
        company: "Fedex",
        location: "Atlanta, GA",
        logo: "fedex_logo.jpeg",
        website: "http://fedex.com",
        industry: "Freight and Package Transportation",
        size: "10,001+ employees",
        pageViews: 1728,
        viewed: null,
        lastSeen: moment("2024-09-05").format("MMM D, YYYY"),
      },
      {
        company: "Rona",
        location: "Boucherville, QC",
        logo: "rona_logo.jpeg",
        website: "https://www.rona.ca/en",
        industry: "Retail",
        size: "10,001+ employees",
        pageViews: 2910,
        viewed: null,
        lastSeen: moment("2024-09-17").format("MMM D, YYYY"),
      },
      {
        company: "Golf Town",
        location: "Vaughan, ON",
        logo: "golf_town_logo.jpeg",
        website: "http://www.joingolftown.com",
        industry: "Retail",
        size: "1,001-5,000 employees",
        pageViews: 1209,
        viewed: null,
        lastSeen: moment("2024-09-09").format("MMM D, YYYY"),
      },
      {
        company: "Cineplex",
        location: "Toronto, ON",
        logo: "cineplex_entertainment_logo.jpeg",
        website: "http://www.cineplex.com",
        industry: "Entertainment Providers",
        size: "10,001+ employees",
        pageViews: 1820,
        viewed: null,
        lastSeen: moment("2024-09-10").format("MMM D, YYYY"),
      },
      {
        company: "Winners",
        location: "Mississauga, ON",
        logo: "winners.png",
        website: "https://www.winners.ca/",
        industry: "Retail",
        size: "10,001+ employees",
        pageViews: 2121,
        viewed: null,
        lastSeen: moment("2024-09-14").format("MMM D, YYYY"),
      },
    ];

    //original props
    var iniProps = {
      cost: "-",
      views: "-",
      clicks: "-",
      // ctr: "N/A",
      // reach: "N/A",
      // engagement: "N/A",
    };
    const isUrl = (str) => {
      const urlPattern = /^(https?:\/\/)[\w.-]+\.[a-zA-Z]{2,}(\/\S*)?$/;
      return urlPattern.test(str);
    };
    var iniChannelData = [
      {
        name: "linkedin",
        icon: "linkedin",
        props: JSON.parse(JSON.stringify(iniProps)),
      },
      {
        name: "display",
        icon: "google",
        props: JSON.parse(JSON.stringify(iniProps)),
      },
      {
        name: "facebook",
        icon: "facebook",
        props: JSON.parse(JSON.stringify(iniProps)),
      },
      {
        name: "instagram",
        icon: "instagram",
        props: JSON.parse(JSON.stringify(iniProps)),
      },
    ];
    return {
      //identified contacts/companies return

      keys1: [
        "Name",
        "Name-Hidden",
        "Job Title",
        "Company",
        "Location",
        "Website",
        "Email",
        "LinkedIn",
        // "Page Views",
        // "Page Views-Hidden",
        // "Viewed",
        // "Last Seen",
      ],
      keys2: [
        "Company",
        "Company-Hidden",
        "Company Location",
        "Website",
        "Industry",
        "Company Size",
        // "Page Views",
        // "Page Views-Hidden",
        // "Viewed",
        // "Last Seen",
      ],
      columns1: [
        {
          data: "name",
          defaultContent: "",
          orderData: [1],
          targets: [0],
          className: "align-middle",
          render: function (data, type, row, meta) {
            if (type === "display") {
              // var url = row.logo ? new URL(`../assets/${row.logo}`, import.meta.url).href : new URL('../assets/na.jpeg', import.meta.url).href;
              // var style =
              //   "height:25px; width:25px; margin: 0 19px 0 0; border-radius: 50%;";
              return (
                '<span class="d-flex align-items-center"><span style="display:inline-block">' +
                data +
                "</span></span>"
              );
            }
          },
        },
        {
          data: "name",
          visible: false,
          searchable: false,
          targets: [1],
        },
        {
          data: "jobTitle",
          className: " align-middle",
        },
        {
          data: "company",
          className: " align-middle",
        },
        {
          data: "location",
          className: " align-middle",
        },
        {
          data: "website",
          className: "text-center align-middle",
          orderable: false,
          render: function (data, type, row, meta) {
            var url = new URL(`../assets/world-wide-web.png`, import.meta.url)
              .href;
            return (
              '<a href="' +
              data +
              '" target="_blank" style="background-color:rgba(10, 102, 194, 0.1); padding: 9px; border-radius: 8px; display: inline-block;"><span class="d-flex align-items-center"> <img src="' +
              url +
              '" style="height:20px; width:20px;" /></span></a>'
            );
          },
        },
        {
          data: "email",
          className: " align-middle",
          defaultContent: "Unknown",
        },
        {
          data: "LinkedIn",
          className: "text-center align-middle",
          orderable: false,
          render: function (data, type, row, meta) {
            var url = new URL(`../assets/linkedin.png`, import.meta.url).href;
            return (
              '<a href="' +
              data +
              '" target="_blank" style="background-color:rgba(10, 102, 194, 0.1); padding: 9px; border-radius: 8px; display: inline-block;"><span class="d-flex align-items-center"> <img src="' +
              url +
              '" style="height:20px; width:20px;" /><span style="display:inline-block"></span></span></a>'
            );
          },
        },

        // {
        //   data: "pageViews",
        //   defaultContent: "-",
        //   orderData: [9],
        //   targets: [8],
        //   className: "text-center align-middle",
        //   render: function (data, type, row, meta) {
        //     if (type === "display") {
        //       return data.toLocaleString(undefined, {
        //         maximumFractionDigits: 1,
        //       });
        //     }
        //   },
        // },
        // {
        //   data: "pageViews",
        //   visible: false,
        //   searchable: false,
        //   targets: [9],
        // },
        // {
        //   data: "viewed",
        //   defaultContent: "-",
        //   className: "text-center align-middle",
        //   render: function (data, type, row, meta) {
        //     if (type === "display") {
        //     }
        //   },
        // },
        // {
        //   data: "lastSeen",
        //   className: "text-center align-middle",
        //   type: "date",
        // },
      ],
      columns2: [
        {
          data: "company",
          defaultContent: "",
          orderData: [1],
          targets: [0],
          className: "align-middle",
          render: function (data, type, row, meta) {
            var url;
            if (
              props.propertyId == "6529ae4a668aae205367dd72" ||
              props.campaignId == "672a7b5e76f6b4d97f9b3ed8"
            ) {
              url = new URL(`../assets/${row.logo}`, import.meta.url).href;
            } else {
              url = row.logo;
              // url = new URL(`../assets/${row.logo}`, import.meta.url).href;
            }
            var style =
              "height:25px; width:25px; margin: 0 19px 0 0; border-radius: 50%;";
            var content;

            if (url) {
              content = '<img src="' + url + '" style="' + style + '" />';
            } else {
              // Return an empty div with the same size as the img if the url is null
              content = '<div style="' + style + '"></div>';
            }

            return (
              '<span class="d-flex align-items-center">' +
              content +
              '<span style="display:inline-block">' +
              data +
              "</span></span>"
            );
          },
        },
        {
          data: "company",
          visible: false,
          searchable: false,
          targets: [1],
        },
        {
          data: "location",
          className: " align-middle",
        },
        {
          data: "website",
          className: "text-center align-middle",
          orderable: false,
          render: function (data, type, row, meta) {
            if (data === null) {
              return "N/A"; // Return nothing if data is null
            }
            var url = new URL(`../assets/world-wide-web.png`, import.meta.url)
              .href;
            return (
              '<a href="' +
              data +
              '" target="_blank" style="background-color:rgba(10, 102, 194, 0.1); padding: 9px; border-radius: 8px; display: inline-block;"><span class="d-flex align-items-center"> <img src="' +
              url +
              '" style="height:20px; width:20px;" /></span></a>'
            );
          },
        },
        {
          data: "industry",
          className: " align-middle",
        },
        {
          data: "size",
          className: " align-middle",
          render: function (data, type, row, meta) {
            return data ? data : "N/A";
          },
        },
        // {
        //   data: "pageViews",
        //   defaultContent: "-",
        //   orderData: [7],
        //   targets: [6],
        //   className: "text-center align-middle",
        //   render: function (data, type, row, meta) {
        //     if (type === "display") {
        //       return data.toLocaleString(undefined, {
        //         maximumFractionDigits: 1,
        //       });
        //     }
        //   },
        // },
        // {
        //   data: "pageViews",
        //   visible: false,
        //   searchable: false,
        //   targets: [7],
        // },
        // {
        //   data: "viewed",
        //   defaultContent: "-",
        //   className: "text-center align-middle",
        //   render: function (data, type, row, meta) {
        //     if (type === "display") {
        //     }
        //   },
        // },
        // {
        //   data: "lastSeen",
        //   className: "text-center align-middle",
        //   type: "date",
        // },
      ],
      allContacts: contacts_data,
      allCompanies: companies_data,
      contacts_identified: ref(null),
      companies_identified: ref(null),
      isContactsLoading: ref(false),
      isCompaniesLoading: ref(false),
      showIdentifiedContacts: ref(false),
      showIdentifiedCompanies: ref(false),
      //original return
      platform: ref("LinkedIn"),
      columns: [
        {
          data: "name",
          defaultContent: "",
          orderData: [1],
          targets: [0],
          width: "30%",
          className: "align-middle",
          render: function (data, type, row, meta) {
            if (type === "display") {
              var url, style;
              if (row.logo) {
                if (isUrl(row.logo)) {
                  url = row.logo;
                } else {
                  url = new URL(`../assets/${row.logo}`, import.meta.url).href;
                }
                style =
                  "height:25px; width:25px; margin: 0 19px 0 0; border-radius: 50%; ";
              } else {
                url = new URL(`../assets/linkedin-company.svg`, import.meta.url)
                  .href;
                style =
                  "height:25px; width:25px; margin: 0 19px 0 0; border-radius: 50%;";
              }
              return (
                '<span class="d-flex align-items-center"><img src="' +
                url +
                '" style="' +
                style +
                '" /><span style="display:inline-block">' +
                data +
                "</span></span>"
              );
            }
          },
        },
        {
          data: "name",
          visible: false,
          searchable: false,
          targets: [1],
        },
        // {
        //   data: "headquarters",
        //   defaultContent: "",
        //   width: "20%",
        //   className: "align-middle",
        // },
        {
          data: "link",
          defaultContent: "-",
          width: "30%",
          className: "align-middle",
          orderData: [1],
          targets: [2],
          render: function (data, type, row, meta) {
            if (type === "display") {
              var url = new URL(`../assets/world-wide-web.png`, import.meta.url)
                .href;
              return (
                '<a href="' +
                data +
                '" target="_blank" style="background-color:rgba(10, 102, 194, 0.1); padding: 9px 25px; border-radius: 8px; display: inline-block;"><span class="d-flex align-items-center"> <img src="' +
                url +
                '" style="height:20px; width:20px; margin: 0 14px 0 0;" /><span style="display:inline-block">' +
                row.name +
                "</span></span></a>"
              );
            }
          },
        },
        {
          data: "impressions",
          defaultContent: "-",
          orderData: [4],
          targets: [3],
          className: "text-center align-middle",
          render: function (data, type, row, meta) {
            if (type === "display") {
              return data.toLocaleString(undefined, {
                maximumFractionDigits: 1,
              });
            }
          },
        },
        // {
        //   data: "clicks",
        //   defaultContent: "-",
        //   orderData: [6],
        //   targets: [4],
        //   className: "text-center align-middle",
        //   render: function (data, type, row, meta) {
        //     if (type === "display") {
        //       return data.toLocaleString(undefined, {
        //         maximumFractionDigits: 1,
        //       });
        //     }
        //   },
        // },
        {
          data: "impressions",
          visible: false,
          searchable: false,
          targets: [4],
        },
        {
          data: "clicks",
          visible: false,
          searchable: false,
          targets: [6],
        },
      ],
      leadsColumns: [
        {
          data: "name",
          defaultContent: "-",
          className: "align-middle",
          render: function (data, type, row, meta) {
            if (type === "display") {
              return data.replace("<", "").replace(">", "");
            }
          },
        },
        {
          data: "email",
          defaultContent: "-",
          className: "align-middle",
        },
        {
          data: "phone",
          defaultContent: "-",
          className: "align-middle",
          render: function (data, type, row, meta) {
            if (type === "display") {
              return data && typeof data == "string"
                ? data.replace("<", "").replace(">", "")
                : data;
            }
          },
        },
        {
          data: "channel",
          defaultContent: "-",
          className: "align-middle",
        },
        {
          data: "submitAt",
          type: "date",
          defaultContent: "-",
          className: "align-middle",
          render: function (data, type, row, meta) {
            return moment(new Date(data)).format("MM/DD/YY");
          },
        },
      ],
      likesColumns: [
        {
          data: "name",
          defaultContent: "-",
          className: "align-middle",
        },
        {
          data: "email",
          defaultContent: "-",
          className: "align-middle",
        },
        {
          data: "jobTitle",
          defaultContent: "-",
          className: "align-middle",
        },
        {
          data: "company",
          defaultContent: "-",
          className: "align-middle",
        },
        {
          data: "channel",
          defaultContent: "-",
          className: "align-middle",
        },
      ],
      keys: [
        "Name",
        "Name-Hidden",
        // "Headquarters",
        "Company Website",
        "Views",
        // "Clicks",
        "Views-Hidden",
        "Clicks-Hidden",
      ],
      likeskeys: ["Name", "Email", "Job Title", "Company", "Channel"],
      leadsKeys: ["Name", "Email", "Phone Number", "Channel", "Became Lead"],
      campaignData: ref({
        campaignName: null,
        cost: null,
        startDate: null,
        impressions: null,
        clicks: null,
        hideReportContractViews: false,
        hideTableContractedViews: false,
        previewInfo: {
          sameUrl: false,
          showExtraSAd: false,
          showCarouselText: false,
          pageName: null,
          introText: null,
          headline: null,
          ctaType: "Learn More",
          websiteUrl: null,
          logo: null,
          files: [],
          cardInfo: [
            { headline: null, description: null, url: null },
            { headline: null, description: null, url: null },
          ],
        },
      }),
      isLoading: ref(true),
      isChartLoading: ref(true),
      isTableLoading: ref(true),
      isLeadTableLoading: ref(true),
      isWebsitesLoading: ref(true),
      isCompaniesLikesLoading: ref(true),
      weeklyData: ref({}),
      monthlyData: ref({}),
      companies: ref([]),
      websites: ref([]),
      leads: ref([]),
      tooltips: tooltips.report,
      detailChannelData: ref({
        total: JSON.parse(JSON.stringify(iniChannelData)),
        // broker: JSON.parse(JSON.stringify(iniChannelData)),
        // tenant: JSON.parse(JSON.stringify(iniChannelData)),
      }),
      // mockUserId: ref(import.meta.env.VITE_MOCK_ACCOUNT_ID),
      // mockCompanies: mockData.companies,
      // mockWebsites: mockData.websites,
      monthAvg: ref("N/A"),
      monthlyBudget: ref(null),
      activeCPM: ref(null),
      extendCPM: ref(null),
      defaultCPM: ref(188),
      contractType: ref(null),
      extendDuration: ref(null),
      previewTabs: ref([]),
      locations: ref([]),
      isMobile: ref(false),
      isTablet: ref(false),
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
            align: "end",
            labels: {
              usePointStyle: true,
              pointStyle: "circle",
              color: "#06152B",
              font: {
                family: "'Sora', sans-serif",
              },
            },
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              color: "#809FB8",
            },
          },
          y: {
            border: {
              display: false,
            },
            ticks: {
              color: "#809FB8",
              stepSize: 200,
              callback: function (label, index, labels) {
                return label.toLocaleString(undefined, {
                  maximumFractionDigits: 1,
                });
              },
            },
          },
        },
      },
      campaignLikes: ref([]),
    };
  },
  props: {
    propertyId: {
      type: String,
      required: true,
    },
    campaignId: {
      type: String,
      required: true,
    },
    dateRange: {
      type: Array,
      required: true,
    },
    token: {
      type: String,
      required: false,
    },
    users: {
      type: Array,
      required: false,
    },
  },
  components: {
    // Line,
    Bar,
    PreviewResults,
    DatatableComponent,
    JsonCSV,
  },
  computed: {
    // contractVal() {
    //   var cpm = this.defaultCPM;
    //   if (this.activeCPM) {
    //     cpm = this.activeCPM;
    //   }
    //   if (this.extendCPM) {
    //     cpm = this.extendCPM;
    //   }
    //   var cost = (this.monthlyBudget / cpm) * 1000;
    //   return cost.toLocaleString(undefined, {
    //     maximumFractionDigits: 0,
    //   });
    // },
    startDate() {
      return moment(this.dateRange[0]).format("YYYY-MM-DD");
    },
    endDate() {
      var endDate = this.dateRange[1];
      if (!endDate) endDate = this.dateRange[0];
      return moment(endDate).format("YYYY-MM-DD");
    },
    kpis() {
      var data = {
        Value: null,
        Views: null,
        Clicks: null,
        // CTR: null,
        // Reach: "N/A",
      };
      if (this.campaignData.campaignName) {
        var cost = this.campaignData.cost;
        data.Value =
          cost == 0
            ? "$" + cost
            : "$" +
              cost.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
        data.Views = this.campaignData.impressions.toLocaleString(undefined, {
          maximumFractionDigits: 1,
        });
        data.Clicks = this.campaignData.clicks.toLocaleString(undefined, {
          maximumFractionDigits: 1,
        });
        // data["Leads"] = this.campaignData.leads.toLocaleString(undefined, {
        //   maximumFractionDigits: 1,
        // });
        // var ctr = this.campaignData.clicks / this.campaignData.impressions;
        // if (isNaN(ctr)) {
        //   data.CTR = "N/A";
        // } else {
        //   data.CTR = ctr.toLocaleString("en-GB", {
        //     style: "percent",
        //     minimumFractionDigits: 2,
        //   });
        // }
      }
      return data;
    },
    chartData() {
      var _this = this;
      var data = {
        labels: [],
        datasets: [
          {
            label: "Actual views",
            data: [],
            backgroundColor: "#4350f2",
            borderRadius: 3,
            barPercentage: 0.6,
          },
          // {
          //   label: "Contracted views",
          //   data: [],
          //   backgroundColor: "#06152B",
          //   borderRadius: 3,
          //   barPercentage: 0.6,
          // },
        ],
      };
      if (Object.keys(this.monthlyData).length > 1) {
        Object.keys(this.monthlyData).forEach((key) => {
          data.labels.push(key);
          data.datasets[0].data.push(_this.monthlyData[key].impressions);
        });
      }

      // var totalMonths = 6;
      // var OriginalMonths = 0;
      // var cpm = this.defaultCPM;
      // var extendCPM = this.extendCPM;
      // if (this.activeCPM) {
      //   cpm = this.activeCPM;
      // }

      // var contractNum = Math.round((this.monthlyBudget / cpm) * 1000);
      // var extend = this.extendDuration ? this.extendDuration : 0;
      // switch (this.contractType) {
      //   case 1:
      //     OriginalMonths = 6;
      //     break;
      //   case 2:
      //     OriginalMonths = 12;
      //     break;
      //   case 3:
      //     OriginalMonths = 1;
      //     break;
      //   case 4:
      //     OriginalMonths = 3;
      //     break;
      // }
      // totalMonths = OriginalMonths + extend;
      // for (var i = 0; i < totalMonths; i++) {
      //   if (i < OriginalMonths) {
      //     data.datasets[1].data.push(contractNum);
      //   } else {
      //     if (extendCPM) {
      //       var contractNum1 = Math.round(
      //         (this.monthlyBudget / extendCPM) * 1000
      //       );
      //       data.datasets[1].data.push(contractNum1);
      //     } else {
      //       data.datasets[1].data.push(contractNum);
      //     }
      //   }
      // }
      return data;
    },
    chartData2() {
      var _this = this;
      var data = {
        labels: [],
        datasets: [
          {
            label: "Actual views",
            data: [],
            backgroundColor: "#4350f2",
            borderRadius: 3,
            barPercentage: 0.6,
          },
        ],
      };
      if (Object.keys(this.monthlyData).length > 1) {
        Object.keys(this.monthlyData).forEach((key) => {
          data.labels.push(key);
          data.datasets[0].data.push(_this.monthlyData[key].impressions);
        });
      }
      return data;
    },
    currentUser() {
      var userId = null;
      if (this.$store.state.user?.userId) {
        userId = this.$store.state.user.userId;
      }
      if (this.$store.state.user?.currentUserId) {
        userId = this.$store.state.user.currentUserId;
      }
      return userId;
    },
    // isMockUser() {
    //   if (
    //     (this.users && this.users.includes(this.mockUserId)) ||
    //     this.currentUser == this.mockUserId
    //   ) {
    //     return true;
    //   }
    //   return false;
    // },
    allLoaded() {
      return (
        !this.isLoading &&
        !this.isChartLoading &&
        !this.isTableLoading &&
        !this.isWebsitesLoading &&
        !this.isCompaniesLikesLoading
      );
    },
    isCompaniesReachedLoading() {
      return this.isTableLoading || this.isLoading;
    },
    campaignLikesExport() {
      var results = [];
      if (this.campaignLikes) {
        this.campaignLikes.forEach((like) => {
          results.push({
            Name: like.name,
            Email: like.email,
            "Job Title": like.jobTitle,
            Company: like.company,
            Channel: like.channel,
          });
        });
      }
      return results;
    },
    reachedCampaignsExport() {
      var results = [];
      if (this.companies) {
        this.companies.forEach((company) => {
          results.push({
            Name: company.name,
            "Company Website": company.link,
            Views: company.impressions,
            // Clicks: company.clicks,
          });
        });
      }
      return results;
    },
  },
  methods: {
    average(ctx) {
      const values = ctx.chart.data.datasets[0].data;
      return values.reduce((a, b) => a + b, 0) / values.length;
    },
    totalReport(startDate, endDate) {
      var info = {
        startDate: startDate,
        endDate: endDate,
      };
      if (this.token) {
        info.token = this.token;
      } else {
        info.campaignId = this.campaignId;
      }
      var _this = this;
      api()
        .totalReport(info)
        .then((res) => {
          if (res.data) {
            _this.campaignData = res.data;
            _this.monthlyBudget = res.data.monthlyBudget;
            _this.activeCPM = res.data.cpm;
            _this.extendCPM = res.data.extendCPM;
            _this.contractType = res.data.contractType;
            _this.extendDuration = res.data.extendDuration;
            if (_this.campaignData.previewInfo.files.length > 0) {
              _this.previewTabs.push({
                type: "carouselAd",
                typeName: "Carousel",
                typeId: 3,
              });
            }
            if (_this.campaignData.previewInfo.adImage) {
              _this.previewTabs.push({
                type: "singleImageAd",
                typeName: "Image A",
                typeId: 2,
              });
            }
            if (_this.campaignData.previewInfo.adImage2) {
              _this.previewTabs.push({
                type: "singleImageAd2",
                typeName: "Image B",
                typeId: 4,
              });
            }
            if (_this.campaignData.previewInfo.adImage3) {
              _this.previewTabs.push({
                type: "singleImageAd3",
                typeName: "Image C",
                typeId: 5,
              });
            }
            if (_this.campaignData.previewInfo.adImage4) {
              _this.previewTabs.push({
                type: "singleImageAd4",
                typeName: "Image D",
                typeId: 6,
              });
            }
            if (_this.campaignData.previewInfo.adImage5) {
              _this.previewTabs.push({
                type: "singleImageAd5",
                typeName: "Image E",
                typeId: 5,
              });
            }
            if (_this.campaignData.previewInfo.adImage6) {
              _this.previewTabs.push({
                type: "singleImageAd6",
                typeName: "Image F",
                typeId: 6,
              });
            }
            if (_this.campaignData.previewInfo.adVideo) {
              _this.previewTabs.push({
                type: "VideoAd",
                typeName: "Video",
                typeId: 1,
              });
            }
            if (_this.previewTabs.length == 0) {
              _this.previewTabs.push({
                type: "carouselAd",
                typeName: "Carousel",
                typeId: 3,
              });
            }
            var monthAvg =
              typeof res.data.monthAvg == "number"
                ? res.data.monthAvg.toLocaleString(undefined, {
                    maximumFractionDigits: 1,
                  })
                : res.data.monthAvg;
            _this.monthAvg = monthAvg;
            _this.setDetailChannelData(res.data);
            _this.locations = res.data.locations
              ? res.data.locations.split("+")
              : [];
            _this.isLoading = false;
            _this.$emit("updateIsLoading", _this.isLoading);
            _this.$emit("updateCampaignData", _this.prepareReportData());
          }
        });
    },
    parseDate(dateStr) {
      // Adjusting year format for parsing
      let parts = dateStr.split(", ");
      parts[1] = "20" + parts[1];
      return new Date(
        parts[1],
        new Date(Date.parse(parts[0] + " 1, 2012")).getMonth(),
        parts[0].split(" ")[1]
      );
    },
    endOfWeek(date) {
      const end = new Date(date);
      end.setDate(end.getDate() + (6 - end.getDay()));
      return end;
    },
    weeklyReport(startDate, endDate) {
      var info = {
        startDate: startDate,
        endDate: endDate,
      };
      if (this.token) {
        info.token = this.token;
      } else {
        info.campaignId = this.campaignId;
      }
      var _this = this;
      api()
        .weeklyReport(info)
        .then((res) => {
          if (res.data) {
            const currentDate = new Date();
            const filteredData = Object.keys(res.data).reduce((acc, key) => {
              const startDate = this.parseDate(key);
              const endDate = this.endOfWeek(startDate);
              if (endDate <= currentDate) {
                acc[key] = res.data[key];
              }
              return acc;
            }, {});
            _this.weeklyData = filteredData;
            _this.isChartLoading = false;
            _this.$emit("updateChartData", this.prepareChartData());
          }
        });
    },
    monthlyReport(startDate, endDate) {
      var info = {
        startDate: startDate,
        endDate: endDate,
      };
      if (this.token) {
        info.token = this.token;
      } else {
        info.campaignId = this.campaignId;
      }
      var _this = this;
      api()
        .monthlyReport(info)
        .then((res) => {
          if (res.data) {
            _this.monthlyData = res.data;
            _this.isChartLoading = false;
          }
        });
    },
    getCompanyReachedReport(startDate, endDate) {
      this.isTableLoading = true;
      var _this = this;
      // if (this.isMockUser) {
      //   if (
      //     moment(this.startDate).isBetween(
      //       "2022-10-01",
      //       "2023-09-30",
      //       null,
      //       "[]"
      //     ) ||
      //     moment(this.endDate).isBetween("2022-10-01", "2023-09-30", null, "[]")
      //   ) {
      //     var mockCompanies = [];
      //     _this.mockCompanies.forEach((company) => {
      //       company.logo = new URL(
      //         `../assets/${company.logo}`,
      //         import.meta.url
      //       );
      //       mockCompanies.push(company);
      //     });
      //     _this.companies = mockCompanies;
      //     _this.$emit("updateCompaniesData", _this.prepareCompaniesData());
      //   }
      //   setTimeout(() => {
      //     _this.isTableLoading = false;
      //   }, 6000);
      // } else {
      var info = {
        campaignId: this.campaignId,
        startDate: startDate,
        endDate: endDate,
      };
      if (this.token) {
        info.token = this.token;
      } else {
        info.campaignId = this.campaignId;
      }
      api()
        .getCompanyReachedReport(info)
        .then((res) => {
          if (res.data) {
            _this.companies = res.data;
            _this.$emit("updateCompaniesData", _this.prepareCompaniesData());
            setTimeout(() => {
              _this.isTableLoading = false;
            }, 500);
          }
        });
      // }
    },
    getWebsitesReport(startDate, endDate) {
      this.isWebsitesLoading = true;
      var _this = this;
      // if (this.isMockUser) {
      //   if (
      //     moment(this.startDate).isBetween(
      //       "2022-10-01",
      //       "2023-09-30",
      //       null,
      //       "[]"
      //     ) ||
      //     moment(this.endDate).isBetween("2022-10-01", "2023-09-30", null, "[]")
      //   ) {
      //     _this.websites = _this.mockWebsites;
      //     _this.$emit("updateWebsitesData", _this.websites);
      //   }
      //   setTimeout(() => {
      //     _this.isWebsitesLoading = false;
      //   }, 3000);
      // } else {
      var info = {
        campaignId: this.campaignId,
        startDate: startDate,
        endDate: endDate,
      };
      if (this.token) {
        info.token = this.token;
      } else {
        info.campaignId = this.campaignId;
      }
      api()
        .getWebsitesList(info)
        .then((res) => {
          if (res.data) {
            _this.websites = res.data;
            _this.$emit("updateWebsitesData", _this.websites);
            setTimeout(() => {
              _this.isWebsitesLoading = false;
            }, 500);
          }
        });
      // }
    },
    getLeadsForCampaign(startDate, endDate) {
      this.isLeadTableLoading = true;
      var info = {
        startDate: startDate,
        endDate: endDate,
      };
      if (this.token) {
        info.token = this.token;
      } else {
        info.campaignId = this.campaignId;
      }
      var _this = this;
      api()
        .getLeadsForCampaign(info)
        .then((res) => {
          if (res.data) {
            _this.leads = res.data;
            setTimeout(() => {
              _this.isLeadTableLoading = false;
            }, 500);
          }
        });
    },
    getImgUrl(name, noExtension = true) {
      if (noExtension) {
        return new URL(`../assets/${name}.png`, import.meta.url).href;
      } else {
        return new URL(`../assets/${name}`, import.meta.url).href;
      }
    },
    getCampaignLikes() {
      var _this = this;
      var info = {
        campaignId: this.campaignId,
      };
      this.isCompaniesLikesLoading = true;
      api()
        .getCampaignLikes(info)
        .then((res) => {
          if (res.data) {
            _this.campaignLikes = res.data;
            _this.isCompaniesLikesLoading = false;
            _this.$emit("updateLikesData", _this.prepareLikesData());
          }
        });
    },
    async getData() {
      this.totalReport(this.startDate, this.endDate);
      this.monthlyReport(this.startDate, this.endDate);

      //get data for identified contacts/companies
      if (
        this.propertyId == "6529ae4a668aae205367dd72" ||
        this.campaignId == "672a7b5e76f6b4d97f9b3ed8"
      ) {
        this.contacts_identified = this.allContacts;
        this.companies_identified = this.allCompanies;
        this.showIdentifiedContacts = true;
        this.showIdentifiedCompanies = true;
        this.$emit("updateContactsIdentifiedData", this.contacts_identified);
        this.$emit("updateCompaniesIdentifiedData", this.companies_identified);
      } else {
        await this.getContacts(this.startDate, this.endDate);
        await this.getCompanies(this.startDate, this.endDate);
      }

      this.getCompanyReachedReport(this.startDate, this.endDate);
      this.getCampaignLikes();
      this.getWebsitesReport(this.startDate, this.endDate);

      // this.getLeadsForCampaign(this.startDate, this.endDate);
    },
    async getContacts(startDate, endDate) {
      this.isContactsLoading = true;
      this.showIdentifiedContacts = false;
      var info = {
        startDate: startDate,
        endDate: endDate,
        propertyId: this.propertyId,
      };
      let res = await api().getMAContacts(info);
      this.isContactsLoading = false;
      if (res.data) {
        this.contacts_identified = res.data;
        if (res.data && Array.isArray(res.data) && res.data.length > 0) {
          this.showIdentifiedContacts = true;
        }
      }
      this.$emit("updateContactsIdentifiedData", this.contacts_identified);
    },
    async getCompanies(startDate, endDate) {
      this.isCompaniesLoading = true;
      this.showIdentifiedCompanies = false;
      var info = {
        startDate: startDate,
        endDate: endDate,
        propertyId: this.propertyId,
      };
      let res = await api().getMACompanies(info);
      this.isCompaniesLoading = false;
      if (res.data) {
        this.companies_identified = res.data;
        if (res.data && Array.isArray(res.data) && res.data.length > 0) {
          this.showIdentifiedCompanies = true;
        }
      }
      this.$emit("updateCompaniesIdentifiedData", this.companies_identified);
    },
    clear() {
      this.isLoading = true;
      this.isChartLoading = true;
      this.isTableLoading = true;
      this.isWebsitesLoading = true;
      this.isLeadTableLoading = true;
      this.isCompaniesLikesLoading = true;
      this.campaignData.campaignName = null;
      this.campaignData.cost = null;
      this.campaignData.impressions = null;
      this.campaignData.clicks = null;
      this.weeklyData = {};
      this.monthlyData = {};
      this.companies = [];
      this.websites = [];
      this.leads = [];
      this.previewTabs = [];
    },
    setDetailChannelData(campaignData) {
      var extraPlatforms = ["twitter", "video", "search"];
      extraPlatforms.forEach((platform) => {
        if (campaignData[platform]) {
          var addSpace = false;
          Object.keys(this.detailChannelData).forEach((key) => {
            var obj = {
              name: platform,
              icon: platform,
              props: {
                cost: "-",
                views: "-",
                clicks: "-",
                // ctr: "N/A",
                // reach: "N/A",
                // engagement: "N/A",
              },
            };
            if (
              this.detailChannelData[key].filter((e) => e.name === platform)
                .length == 0
            ) {
              addSpace = true;
              this.detailChannelData[key].push(obj);
            }
          });
          if (addSpace) {
            var conatiner = $(".tabs.channel-section .tab-content .tab-pane");
            conatiner.height(conatiner.height() + 51);
          }
        }
      });
      Object.keys(this.detailChannelData).forEach((key) => {
        this.detailChannelData[key].map((item) => {
          var ele = campaignData[item.name];
          if (ele) {
            var cost = ele[key].cost ? ele[key].cost : 0;
            item.props.cost =
              "$" +
              cost.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
            item.props.views = ele[key].impressions.toLocaleString(undefined, {
              maximumFractionDigits: 1,
            });
            item.props.clicks = ele[key].clicks.toLocaleString(undefined, {
              maximumFractionDigits: 1,
            });
            // item.props.ctr = isNaN(ele[key].clicks / ele[key].impressions)
            //   ? "N/A"
            //   : (ele[key].clicks / ele[key].impressions).toLocaleString(
            //       "en-GB",
            //       {
            //         style: "percent",
            //         minimumFractionDigits: 2,
            //       }
            //     );
            // item.props.reach =
            //   ele[key].reach != null
            //     ? ele[key].reach.toLocaleString(undefined, {
            //         maximumFractionDigits: 1,
            //       })
            //     : "N/A";
            // item.props.engagement =
            //   ele[key].engagements != null
            //     ? ele[key].engagements.toLocaleString(undefined, {
            //         maximumFractionDigits: 1,
            //       })
            //     : "N/A";
          }
          return item;
        });
      });
    },
    prepareReportData() {
      var result = {
        cost: this.kpis.Value,
        views: this.kpis.Views,
        clicks: this.kpis.Clicks,
        monthAvg: this.monthAvg,
        hideReportContractViews: this.campaignData.hideReportContractViews,
        hideTableContractedViews: this.campaignData.hideTableContractedViews,
        contractVal: this.contractVal,
        detailChannelData: this.detailChannelData,
      };
      return result;
    },
    prepareChartData() {
      var chartData;
      if (this.campaignData.hideTableContractedViews) {
        chartData = this.chartData2;
      } else {
        chartData = this.chartData;
      }
      return chartData;
    },
    prepareCompaniesData() {
      return this.companies
        .sort((a, b) => b.impressions - a.impressions)
        .slice(0, 15);
    },
    prepareLikesData() {
      this.campaignLikes = this.campaignLikes.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      return this.campaignLikes;
    },
  },
  mounted() {
    this.getData();
    if (window) {
      this.isMobile = window.matchMedia(
        "only screen and (max-width: 767px)"
      ).matches;
      this.isTablet = window.matchMedia(
        "only screen and (max-width: 991px) and (min-width: 768px)"
      ).matches;
    }
  },
  watch: {
    dateRange(newVal, oldVal) {
      if (oldVal.length > 0) {
        this.clear();
        this.getData();
      }
    },
    allLoaded(val) {
      if (val) {
        this.$emit("enablePDFDownload", val);
      }
    },
    campaignId(newVal, oldVal) {
      if (newVal) {
        this.clear();
        this.getData();
      }
    },
  },
};
</script>
